<template lang="pug">
.full-box.flex-center
  img(:src="noPage" width="150px")
  .empty-text 找不到页面了，请检查菜单配置...
</template>

<script>
import noPage from '@/assets/imgs/404.png'

export default {
  name: 'Page404',
  data () {
    return {
      noPage
    }
  }
}
</script>

<style lang="sass" scoped>
.flex-center
  flex-direction: column
  .empty-text
    font-size: 14px
    color: $--color-text-placeholder
</style>
